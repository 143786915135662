import React from 'react';
import Link from 'next/link';
import * as Icon from 'react-feather';
import { getCookie,hasCookie } from 'cookies-next';
import {useRouter} from 'next/router'
import parse from 'html-react-parser';
import AppContext from '../../AppContext'
import { useContext } from "react";

const Footer = ({company,social,icon,desc}) => {

    const router = useRouter()
    const context = useContext(AppContext);

    company = company||{};
    const currentYear = new Date().getFullYear();
    const Socials = (social)?social.map((data,idx)=>{
        return(
            <li key={idx}>
                <Link href={data.attributes.Link}>
                    {
                        (data.attributes.Type =='Facebook')
                        ?(<a className="facebook" target="_blank"><Icon.Facebook /></a>)
                        :(data.attributes.Type =='Instagram')
                        ?(<a className="instagram" target="_blank"><Icon.Instagram /></a>)
                        :(data.attributes.Type =='Twitter')
                        ?(<a className="twitter" target="_blank"><Icon.Twitter /></a>)
                        :(data.attributes.Type =='Youtube')
                        ?(<a className="youtube" target="_blank"><Icon.Youtube /></a>):""
                    }
                </Link>
            </li>
        );
    }):"";

    const navigate = (url) => {

        const urlNav = `${url}/`
        const urlBar = router.asPath
        
        if(urlNav != urlBar) {
            context.setLoading(true)
            document.body.classList.add("overflow-hidden");
            setTimeout(() => {
                router.push(url)
            }, 500);
        }
    }

    function convertString(string)
    {
        const array = string.split('/')
        return array[2];
    }
    
    return (
        <footer className="footer-area bg-f7fafd">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-6">
                        <div className="single-footer-widget">
                            <div className="logo">
                                <a onClick={() => navigate(`/`)}>
                                    <img src={`/api/util?img=${encodeURIComponent(icon)}`} width="200px" alt="logo" />
                                </a>
                            </div>
                            {desc != undefined && desc != '' ? parse(desc) : ''}
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                        <div className="single-footer-widget pl-5">
                            <h3>Menu</h3>
                            <ul className="list">
                                <li>
                                    <a onClick={() => navigate(`/`)}>
                                        {
                                            hasCookie('googtrans') 
                                            ?   convertString(getCookie('googtrans')) == 'id'
                                                ?   'Beranda'
                                                :   'Home'
                                            :   'Home'
                                        }
                                    </a>
                                </li>
                                <li>
                                    <a onClick={() => navigate(`/product/motor/`)}>
                                        Product
                                    </a>
                                </li>
                                <li>
                                    <a onClick={() => navigate(`/news`)}>
                                        News
                                    </a>
                                </li>
                                {/* <li>
                                    <Link href="/pricing">
                                        <a>Carrer</a>
                                    </Link>
                                </li> */}
                                <li>
                                    <a onClick={() => navigate(`/about`)}>
                                        About Us
                                    </a>
                                </li>
                                <li>
                                    <a onClick={() => navigate(`/contact`)}>
                                        Contact
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div> 

                    {/* <div className="col-lg-3 col-md-6">
                        <div className="single-footer-widget">
                            <h3>Support</h3>
                            <ul className="list">
                                <li>
                                    <Link href="/faq">
                                        <a>FAQ's</a>
                                    </Link>
                                </li> 
                                <li>
                                    <Link href="/privacy-policy">
                                        <a>Privacy Policy</a>
                                    </Link>
                                </li>
                                <li>
                                    <Link href="/term-condition">
                                        <a>Terms & Condition</a>
                                    </Link>
                                </li>
                                <li>
                                    <Link href="/team">
                                        <a>Team</a>
                                    </Link>
                                </li>
                                <li>
                                    <Link href="/yamaha/ContactUs">
                                        <a>Contact Us</a>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div> */}

                    {/*<div className="col-lg-6 col-md-6">
                        <div className="single-footer-widget">
                            <h3>Address</h3>
                            
                            <ul className="footer-contact-info">
                                <li> 
                                    <Icon.MapPin />
                                    {company.Address}
                                </li>
                                <li>
                                    <Icon.Mail />
                                    Email: <a href={`mailto:${company.Email}`}>{company.Email}</a>
                                </li>
                                <li> 
                                    <Icon.PhoneCall />
                                    Phone: <a href={`tel:${company.Phone}`}>{company.Phone}</a>
                                </li>
                            </ul>
                            <ul className="social-links">
                                {Socials}
                            </ul>
                        </div>
                    </div>
                    */}
                    
                    <div className="col-lg-12 col-md-12">
                        <div className="copyright-area">
                            {/* <p>Copyright &copy; {currentYear} All Right Reserved by <a href="https://thamrin.co.id/" target="_blank">PT THAMRIN BROTHERS</a></p> */}
                            <p>Copyright &copy; {currentYear} All Right Reserved by PT Citra Lestari Mobilindo</p>
                        </div>
                    </div>
                    
                    
                </div>
            </div>

            <img src="/images/map.png" className="map" alt="map" />

            {/* Shape Images */}
            <div className="shape1">
                <img src="/images/shape1.png" alt="shape" />
            </div>
            <div className="shape8 rotateme">
                <img src="/images/shape2.svg" alt="shape" />
            </div>
        </footer>
    )
     
}

export default Footer; 